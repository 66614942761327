var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React from 'react';
import { BsCardChecklist, BsEnvelopeExclamation, BsLightbulb } from 'react-icons/all';
import { useHistory } from 'react-router-dom';
import { useTheme } from 'styled-components';
import { useFlashMessage } from '@app/components/obj.flash-message';
import { axiosClient } from '@app/configure-app';
import { AppPath } from '@app/routes';
import { FlashMessageEnum } from '@atomic';
import { NotificationType } from '../INotification';
import { BacksideContainer, NotificationCardContainer, FlexRowSimple, TitleCardText, SubtitleCardText, DatetimeCardText, } from './styles';
var READ_NOTIFICATION_URL = "notificacao-usuario/:id/ler";
export var NotificationCard = function (_a) {
    var notification = _a.notification, isFirst = _a.isFirst, isLast = _a.isLast;
    var history = useHistory();
    var theme = useTheme();
    var showFlashMessage = useFlashMessage()[0];
    var _b = React.useState(notification), cardNotification = _b[0], setCardNotification = _b[1];
    var handleOnCardClick = function () { return __awaiter(void 0, void 0, void 0, function () {
        var url, response;
        var _a, _b, _c;
        return __generator(this, function (_d) {
            switch (_d.label) {
                case 0:
                    url = READ_NOTIFICATION_URL.replace(':id', cardNotification.id.toString());
                    return [4 /*yield*/, axiosClient.post(url)];
                case 1:
                    response = _d.sent();
                    if (((_a = response === null || response === void 0 ? void 0 : response.data) === null || _a === void 0 ? void 0 : _a.succeeded) && ((_b = response === null || response === void 0 ? void 0 : response.data) === null || _b === void 0 ? void 0 : _b.data)) {
                        // cardNotification.lido = response?.data?.data?.lido;
                        setCardNotification((_c = response === null || response === void 0 ? void 0 : response.data) === null || _c === void 0 ? void 0 : _c.data);
                    }
                    switch (notification.entityType) {
                        case NotificationType.COMUNICADO:
                            handleClickComunicado();
                            break;
                        case NotificationType.CURSO:
                            handleClickCurso();
                            break;
                        case NotificationType.QUESTIONARIO:
                            handleClickQuestionario();
                            break;
                    }
                    return [2 /*return*/];
            }
        });
    }); };
    var handleClickComunicado = function () {
        history.push("/quiz-comunicado/" + notification.entityId);
    };
    var handleClickCurso = function () { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, axiosClient.get("curso/" + notification.entityId)];
                case 1:
                    response = _a.sent();
                    if (response.data.data) {
                        history.push("/track/" + response.data.data.codigo);
                    }
                    else {
                        showFlashMessage(FlashMessageEnum.alert, 'Curso não encontrado.');
                    }
                    return [2 /*return*/];
            }
        });
    }); };
    var handleClickQuestionario = function () {
        history.push({ pathname: AppPath.Question + "/" + notification.entityId });
    };
    return (React.createElement(BacksideContainer, { onClick: handleOnCardClick, borderColor: '#D9D9D9', backgroundColor: '#FFFFFF', style: {
            marginTop: isFirst ? '8px' : '0px',
            borderTopLeftRadius: isFirst ? '16px' : '0px',
            borderTopRightRadius: isFirst ? '16px' : '0px',
            borderBottomLeftRadius: isLast ? '16px' : '0px',
            borderBottomRightRadius: isLast ? '16px' : '0px',
        } },
        cardNotification.entityType === NotificationType.CURSO && (React.createElement(NotificationCardContainer, { style: { marginBottom: '6px' } },
            React.createElement(FlexRowSimple, null,
                React.createElement(BsLightbulb, { style: { fontSize: '3.5rem', color: '#49443C' } }),
                React.createElement("div", null,
                    React.createElement(FlexRowSimple, null,
                        !cardNotification.lido && React.createElement(TitleCardText, null, "Novo Curso"),
                        cardNotification.lido && React.createElement(TitleCardText, null, "Atualiza\u00E7\u00F5es de Curso")),
                    React.createElement(FlexRowSimple, null,
                        React.createElement(SubtitleCardText, null, cardNotification.titulo)))),
            React.createElement(FlexRowSimple, { style: { justifyContent: 'end' } }, cardNotification.data && React.createElement(DatetimeCardText, null, cardNotification.data)))),
        cardNotification.entityType === NotificationType.QUESTIONARIO && (React.createElement(NotificationCardContainer, { style: { marginBottom: '6px' } },
            React.createElement(FlexRowSimple, null,
                React.createElement(BsCardChecklist, { style: { fontSize: '3.5rem', color: '#49443C' } }),
                React.createElement("div", null,
                    React.createElement(FlexRowSimple, null,
                        !cardNotification.lido && React.createElement(TitleCardText, null, "Nova Pesquisa"),
                        cardNotification.lido && React.createElement(TitleCardText, null, "Pesquisa Pendente")),
                    React.createElement(FlexRowSimple, null,
                        React.createElement(SubtitleCardText, null, cardNotification.titulo)))),
            React.createElement(FlexRowSimple, { style: { justifyContent: 'end' } }, cardNotification.data && React.createElement(DatetimeCardText, null, cardNotification.data)))),
        cardNotification.entityType === NotificationType.COMUNICADO && (React.createElement(NotificationCardContainer, { style: { marginBottom: '6px' } },
            React.createElement(FlexRowSimple, null,
                React.createElement(BsEnvelopeExclamation, { style: { fontSize: '3.5rem', color: '#49443C' } }),
                React.createElement("div", null,
                    React.createElement(FlexRowSimple, null,
                        !cardNotification.lido && React.createElement(TitleCardText, null, "Novo Comunicado"),
                        cardNotification.lido && React.createElement(TitleCardText, null, "Comunicado Pendente")),
                    React.createElement(FlexRowSimple, null,
                        React.createElement(SubtitleCardText, null, cardNotification.titulo)))),
            React.createElement(FlexRowSimple, { style: { justifyContent: 'end' } }, cardNotification.data && React.createElement(DatetimeCardText, null, cardNotification.data))))));
};
